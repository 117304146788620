const production = true;

/* CHERY */
const appData = {
    appVersion: "v-1.1.0",
    appProduction: production,
    appDefaultArea: "JAWABARAT",
    appCompanyCode: "chery",
    appCompanyName: "AmartaChery",
    appCode: "CHERY",
    appName: "Chery",
    appAbbreviation: "CHR",
}

export const appProduction = appData.appProduction;
export const appCompanyCode = appData.appCompanyCode;
export const appCompanyName = appData.appCompanyName;
export const appCode = appData.appCode;
export const appName = appData.appName;
export const appAbbreviation = appData.appAbbreviation;
export const appVersion = appData.appVersion;
export const appDefaultArea = appData.appDefaultArea;
